<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <!-- <div class="box-ui-check searchbar-content">
        <div class="title">
          <i class="fas fa-cog"></i>
          <span>선택</span> :
        </div>
        <select class="reqMember" v-model="reqData.companyType">
          <option value="">{{ $t('searchArea.total') }}</option>
          <option value="member">{{ $t('common.member') }}</option>
        </select>
      </div> -->
      <div class="searchBar">
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('searchArea.datetype') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate"/>
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('searchArea.input') }}</span></div>
           <div :class="'searchbar-content'">
             <input type="text" class="w100 mr-5" :placeholder="$t('searchArea.id')" v-model="reqData.memId" />
             <input type="text" class="w124 mr-5" :placeholder="$t('searchArea.ipAddress')" v-model="reqData.ip" />
             <button class="btn-search mr-5" type="button" @click="pageSeach">
               <i class="fas fa-search"></i>
             </button>
             <button class="btn-search" type="button" @click="resetSearch">
               <span>{{$t('button.resetSearch')}}</span>
             </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value" v-if="memList.length !== 0">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value" v-if="memList.length !== 0">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value" v-if="memList.length !== 0">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="memList.length!==0">
            <tr v-for="(item, idx) in memList" :key="idx">
              <td>
                <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
              </td>
              <td>
                <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.memId)">{{ item.memNick }}</button>
              </td>
              <td :class="item.partnerLevel">
                 <span class="box">{{computedPartnerLevel(item)}}</span>
              </td>
              <td>
                 <div class="topwrap" v-if="item.topUserList && item.topUserList.length > 0 && item.topUserList !== '0'">
                    <select class="select">
                       <option class="option" v-for="pt in item.topUserList" :key="pt.recommenderId">
                          <span class="topbox">[{{computedPartnerLevel(pt)}}] </span>
                          <span>{{pt.recommenderId}}</span>
                       </option>
                    </select>
                    <i class="icon">+</i>
                 </div>
                  <div v-else>
                    -
                  </div>
              </td>
              <td @click="openMemberPopup(item, 'isOpenBLock')" class="block roboto">{{ item.ip }}</td>
              <td>{{ item.actionText }}</td>
              <td>{{ item.siteDomain }}</td>
              <td>{{ item.logDesc }}</td>
              <td class="roboto">{{ item.actionDate }}</td>
            </tr>
          </tbody>
          <template v-else>
              <td colspan="13">{{ $t('txt.noData') }}</td>
            </template>
        </table>
      </div>
    </div>
    <pagination v-if="memList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="isOpenBLock">
      <div class="modal-wrap2">
        <div class="sub-title2">
          <h2>{{ $t('common.ipBlack') }}</h2>
          <h4>{{ getDomain() }}</h4>
          <a class="btn" @click="isOpenBLock = false;selectMember = null"><i class="fa fa-times"></i></a>
        </div>
        <div class="modal-content2">
          <ul class="modal-info">
            <li class="ip-txt">
              <span>IP :</span>
              <span class="fc-org">{{ selectMember.ip }}</span>
            </li>
          </ul>
          <p class="modal-txt">{{ $t('txt.blockIP') }}</p>
          <div class="modal-btns">
            <a @click="setDenyIP(selectMember)">{{ $t('button.confirm') }}</a>
            <a class="btn" @click="isOpenBLock = false;selectMember = null">{{ $t('searchArea.cancel') }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import SearchBar from '@/components/main/top/SearchBar.vue'
import TableHead from '@/components/main/table/Head.vue'
import BtnVisibleToggle from '@/components/ui/BtnVisibleToggle'
import SearchFilter from '@/components/common/SearchFilter'
import DateSelector from '@/components/common/DateSelector'
import Pagination from '@/components/common/Pagination'
import UiSelect from '@/components/ui/Select'
import UiCheckSet from '@/components/ui/UiCheckSet'
import UiCheck from '@/components/ui/UiCheck'
import { userTracking, setMemberDenyIP } from '@/api/member.js'
import { replaceDateT, numberWithCommas, getDateStr } from '@/libs/utils.js'
import Memo from '@/components/common/memo'
import { PARTNER_LEVEL } from '@/libs/constants'

export default {
  name: 'UserTracking',
  components: {
    UiSelect,
    UiCheckSet,
    UiCheck,
    BtnVisibleToggle,
    TableHead,
    // BtnHideOpen,
    SearchFilter,
    Pagination,
    DateSelector,
    Memo
  },
  data () {
    return {
      isOpenBLock: false,
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ['memId', 'memNick', 'level', 'upper', 'connectIp', 'actionType', 'site', 'loginDomain', 'loginDt']
      },
      selectMember: null,
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        memId: '',
        startDate: '',
        endDate: '',
        ip: '',
        companyType: ''
      },
      srchFiltersProp: {
        selectOptions: [
          {
            useAll: false,
            opts: [
              {
                text: 'id',
                value: 'memId'
              },
              {
                text: 'ip',
                value: 'ip'
              }
            ]
          }
        ]
      },
      tableData: [],
      btnText: '보기',
      memList: [],
      pageInfo: {},
      totalInfo: {},
      wlContentsActive: false
    }
  },
  methods: {
    computedPartnerLevel (item) {
      return PARTNER_LEVEL[item.partnerLevel]
    },
    async setDenyIP (item) {
      const req = {
        memId: item.memId,
        ip: item.ip
      }
      const res = await setMemberDenyIP(req)
      if (res.resultCode === '0') {
        alert('로그인 IP 차단 완료')
      } else {
        alert('로그인 IP 차단, 다시 시도해주세요.')
      }
      this.isOpenBLock = false
    },
    getDomain () {
      return location.host
    },
    setStartDate (date) {
      console.log(date[0])
      this.reqData.startDate = getDateStr(date[0], 'yyyy-MM-dd hh:mm:ss')
    },
    setEndDate (date) {
      console.log(date[0])
      this.reqData.endDate = getDateStr(date[0], 'yyyy-MM-dd hh:mm:ss')
    },
    pageSeach () {
      this.setTableData(1)
    },
    resetSearch () {
      this.reqData = {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        memId: '',
        startDate: '',
        endDate: '',
        companyType: '',
        ip: ''
      }
    },
    wlContentsToggle () {
      const reverseArr = this.additionalHead.reverse()
      const addPosition = this.headInfo.dataList.indexOf('wl') + 1
      this.wlContentsActive = !this.wlContentsActive
      for (const item of reverseArr) {
        if (this.wlContentsActive) {
          this.headInfo.dataList.splice(addPosition, 0, item)
        } else {
          this.headInfo.dataList.splice(addPosition, 1)
        }
      }
    },
    openMemberPopup (item, type) {
      this[type] = !this[type]
      this.selectMember = item
    },
    openDetail_company () {
      // console.log(id)
    },
    async setTableData (pageNum) {
      this.emitter.emit('Loading', true)
      if (pageNum) {
        this.reqData.page = pageNum
      }
      const data = this.reqData
      const listRes = await userTracking(data)
      console.log(listRes)
      if (listRes.data.pageInfo) {
        const pageInfo = listRes.data.pageInfo
        pageInfo.tatal_page_count = Math.ceil(Number(pageInfo.tatal_list_count) / Number(pageInfo.count_per_list))
        this.pageInfo = pageInfo
      }
      if (listRes.data.totalInfo) {
        const totalInfo = listRes.data.totalInfo
        this.totalInfo = totalInfo
      }

      const memList = listRes.data.list
      memList.forEach(items => {
        for (const item in items) {
          if (item === 'actionType') {
            switch (items[item]) {
              case 'TOKEN':
                items.actionText = this.$t('table.body.move')
                break
              case 'LOGIN':
                items.actionText = this.$t('table.body.login')
                break
              case 'ADMINLOGINTOKEN':
                items.actionText = this.$t('table.body.adminPage')
                break
              case 'ADMINLOGIN':
                items.actionText = this.$t('table.body.adminlogin')
                break
            }
          }
          if (item === 'actionDate' || item === 'lastLoginDt') {
            items[item] = replaceDateT(items[item])
          }
          if (!isNaN(Number(items[item]))) {
            items[item] = numberWithCommas(items[item])
          }
        }
      })
      this.memList = memList

      this.emitter.emit('Loading', false)
    }
  },
  async created () {
    this.emitter.emit('Loading', true)
    console.log(this.$route.query.id)
    if (this.$route.query.id) {
      this.reqData.memId = this.$route.query.id
    }
    await this.setTableData(1)
    this.emitter.emit('Loading', false)
  }
}
</script>

<style scoped>
.block:hover {text-decoration: underline;cursor: pointer;}
.active .btn-t-inner {
  display: none;
}
.fstColumn.userList .levelColor {
  display: block;
}

.box-hideOpen .levelColor {
  background: rgb(255, 218, 38);
  width: 8px;
}
</style>
